<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="destino.value"
                label="Destino"
                item-text="name"
                item-value="id"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(destino.value, 60),
                    rules.requiredTrim(destino.value)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  name: "EditDestino",
  props: ["id"],
  data: () => ({
    isFormValid: false,
    formEditTitle: "Editar destino",
    rules: rules,
    destinos: [],
    destino: {}
  }),
  created() {
    if (this.id != null) {
      this.setDestino(this.id);
    } else {
      this.newDestino();
    }
    this.loadDestinos();
  },
  methods: {
    ...mapActions({
      getDestinos: "configuracionME/getDestinos",
      getDestinosById: "configuracionME/getDestinosById",
      postDestinos: "configuracionME/postDestinos",
      setAlert: "user/setAlert"
    }),
    async loadDestinos() {
      const response = await this.getDestinos();
      this.destinos = response;
    },
    async newDestino() {
      this.formEditTitle = "Nuevo destino";
    },
    async setDestino() {
      const response = await this.getDestinosById(this.id);
      this.destino = response;
      this.destino.id = response.id;
      this.destino.value = response.value;
    },
    async saveEdit() {
      const data = {
        id: this.destino.id,
        value: this.destino.value
      };
      const res = await this.postDestinos(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
