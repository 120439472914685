<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-data-table
      :headers="headers"
      :items="destinos"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top >
        <v-toolbar flat>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" align="end" v-if="canCreate">
              <v-btn color="primary" @click="openModal()" class="to-right"> Nuevo </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="canEdit">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="openModal(item.id)"
            >
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar destino</span>
        </v-tooltip>
        <v-tooltip left v-if="canDelete">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="deleteDestino(item.id)"
            >
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Eliminar destino</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="500px"
      @keydown.esc="closeModal"
      persistent
    >
      <EditDestino :id="id" @closeAndReload="closeAndReload"></EditDestino>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditDestino from "@/components/modules/mesaEntrada/configuracion/EditDestino.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "DestinosDespachos",
  components: { PageHeader, DeleteDialog, EditDestino, GoBackBtn },

  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    showDeleteModal: false,
    search: "",
    searchIcon: enums.icons.SEARCH,
    idToDelete: null,
    routeToGo: "ConfiguracionMesaEntrada",
    openModalEdit: false,
    formEditTitle: "",
    title: enums.titles.DESTINOS_DESPACHOS_MESA_ENTRADA,
    titleDelete: "¿Eliminar destino?",
    rules: rules,
    destinos: [],
    destino: {},
    headers: [
      {
        text: "Destinos",
        align: "start",
        value: "value",
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.loadDestinos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getDestinos: "configuracionME/getDestinos",
      deleteDestinos: "configuracionME/deleteDestinos",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_DESTINOS:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_DESTINOS:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_DESTINOS:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadDestinos() {
      const response = await this.getDestinos();
      this.destinos = response;
    },
    deleteDestino(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteDestinos({
        id: this.idToDelete,
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadDestinos();
      }
    },
    openModal(id) {
      this.openModalEdit = true;
      this.id = id;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadDestinos();
    },
  },
};
</script>

<style lang="scss" scoped></style>
